<button class="user-menu__btn" (click)="toggleMenu($event)" [ngClass]="{ light: (1 | colourTheme) === false }">
    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
            fill="currentColor"
        />
    </svg>
    <div
        class="user-menu__modal"
        [ngClass]="{ light: (1 | colourTheme) === false }"
        *ngIf="showMenu"
        (clickOutside)="clickOutside()"
        (click)="$event.stopPropagation()"
    >
        <div class="user-menu__modal-item">
            <p class="user-menu__modal-item-title" [ngClass]="{ light: (1 | colourTheme) === false }">
                {{ 'header.userMenu.language' | translate }}
            </p>
            <div class="user-menu__modal-values-wrapper">
                <p
                    class="user-menu__modal-value"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    *ngFor="let item of languageArray"
                    [class.user-menu__modal-value--active]="item === currentLocale"
                    (click)="selectLang(item); $event.stopPropagation()"
                >
                    {{ item }}
                </p>
            </div>
        </div>
        <a
            routerLink="/ak/account"
            routerLinkActive="active"
            *ngIf="hasAK"
            class="profile-item"
            [ngClass]="{ light: (1 | colourTheme) === false }"
        >
            {{ 'header.userMenu.AKProfile' | translate }}
        </a>
        <a
            routerLink="/cabinet/my-pension/pension"
            routerLinkActive="active"
            *ngIf="hasPK"
            class="profile-item"
            [ngClass]="{ light: (1 | colourTheme) === false }"
        >
            {{ 'header.userMenu.PKProfile' | translate }}
        </a>
    </div>
</button>
