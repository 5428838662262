import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-named-datepicker',
    templateUrl: './named-datapicker.component.html',
    styleUrls: ['./named-datapicker.component.scss'],
})
export class NamedDatepickerComponent implements OnInit {
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter();
    @Input() type: 'input' | 'icon' = 'icon';
    @Input() currentDate: Date = new Date();
    @Input() isBirth = false;
    @Input() title = '';
    formControl: FormControl;
    currentValue: Date = new Date();
    @Input() disabled!: boolean;
    @Input() maxDate!: Date;
    @Input() minDate!: Date;

    constructor() {}

    ngOnInit(): void {
        this.formControl = new FormControl({ value: this.currentDate, disabled: false });
        this.formControl.valueChanges.subscribe((value) => {
            this.currentValue = value;
            return this.dateChanged.emit(value);
        });
        this.maxDate = this.calculateMaxDate(new Date(), new Date(this.currentDate.getFullYear(), 11, 24));
    }

    private calculateMaxDate(today: Date, christmasDay: Date): Date {
        return new Date(christmasDay.getTime() - 24 * 60 * 60 * 1000);
    }



    reformateDate(dt) {
        return !dt
            ? ''
            : (dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()) +
                  '.' +
                  (dt.getMonth() > 8 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)) +
                  '.' +
                  dt.getFullYear();
    }
}
