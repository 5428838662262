import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocaleService } from 'src/app/core/services/locale.service';
import { EdjsParser } from './edjsParser';

@Component({
    selector: 'app-content-view',
    templateUrl: './content-view.component.html',
    styleUrls: ['./content-view.component.scss'],
})
export class ContentViewComponent implements AfterViewInit, OnChanges {
    constructor(private localeService: LocaleService) {}

    divElement: HTMLElement;
    private editor: EdjsParser;

    @Input('content')
    content: any;

    data;

    ngAfterViewInit() {
        this.divElement = document.getElementById('editorJSParser');
        this.initializeEditor();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.editor) return;

        if (!changes.content.currentValue) {
            this.clearEditor();
            return;
        }

        this.loadEditorContent();
    }

    clearEditor() {
        this.data = null;
        this.divElement.innerHTML = '';
    }

    loadEditorContent() {
        if (this.editor && this.content) {
            this.data = this.editor.parse(this.content);
            this.divElement.innerHTML = this.data;
        }
    }

    initializeEditor() {
        this.editor = new EdjsParser({
            readOnly: true,
            inlineToolbar: true,
            minHeight: 200,
            data: this.content || null,
        });

        this.loadEditorContent();
    }
}
