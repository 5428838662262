<div>
    <div class="colapseButtonWithoutNavigation" *ngIf="!isOpen" (click)="menuOpen()">
        <svg class="rotated" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                class="svgActive"
                d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                fill="var(--primary)"
            />
        </svg>
        {{ 'cabinet.navigation.menu' | translate }}
    </div>

    <!--  <app-navigation-menu *ngIf="showNavigation"></app-navigation-menu> -->
    <div class="nav-menu-tablet" *ngIf="isOpen">
        <div class="menuColapseButtonTablet">
            <li class="menu-list__item">
                <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
                </a>
            </li>
        </div>
        <div class="companySelectionContainer">
            <div *ngIf="logoUrl" class="logo custom-logo">
                <img class="logo-img" [src]="logoUrl" alt="logo" />
            </div>
            <div *ngIf="!logoUrl" class="logo">
                <img src="../../../../../assets/img/clanLogo/swarm_logo_svg.png" alt="logo" />
            </div>
            <!-- Company selection -->
            <!-- <div class="dropDown" *ngIf="companyNameMap">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'cabinet.companies' | translate}}</mat-label>
                <mat-select [formControl]="companyForm" multiple>
                  <mat-select-trigger>
                      {{companyNameMap.get(companyForm.value?.[0]?.companyId)}}
                    <span *ngIf="(companyForm.value?.length || 0) > 1" class="example-additional-selection">
                      (+{{(companyForm.value?.length || 0) - 1}} {{companyForm.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                  <mat-option (onSelectionChange)="changeCompany($event)" *ngFor="let company of trinities" [value]="company">{{companyNameMap.get(company.companyId)}}</mat-option>
                </mat-select>
            </mat-form-field>
            </div> -->
        </div>
        <ul class="nav-menu__list menu-list">
            <li class="menu-list__item" *ngIf="!showCabinet">
                <a
                    routerLink="/home"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M12.2604 4.74751C12.1906 4.68075 12.0978 4.64349 12.0012 4.64349C11.9046 4.64349 11.8118 4.68075 11.742 4.74751L3.1123 12.9914C3.07566 13.0265 3.0465 13.0686 3.0266 13.1153C3.0067 13.1619 2.99647 13.2121 2.99652 13.2628L2.99512 21C2.99512 21.3979 3.15315 21.7794 3.43446 22.0607C3.71576 22.342 4.09729 22.5 4.49512 22.5H8.99981C9.19872 22.5 9.38948 22.421 9.53014 22.2804C9.67079 22.1397 9.74981 21.9489 9.74981 21.75V15.375C9.74981 15.2756 9.78932 15.1802 9.85964 15.1099C9.92997 15.0395 10.0254 15 10.1248 15H13.8748C13.9743 15 14.0696 15.0395 14.14 15.1099C14.2103 15.1802 14.2498 15.2756 14.2498 15.375V21.75C14.2498 21.9489 14.3288 22.1397 14.4695 22.2804C14.6101 22.421 14.8009 22.5 14.9998 22.5H19.5026C19.9004 22.5 20.282 22.342 20.5633 22.0607C20.8446 21.7794 21.0026 21.3979 21.0026 21V13.2628C21.0027 13.2121 20.9925 13.1619 20.9726 13.1153C20.9527 13.0686 20.9235 13.0265 20.8868 12.9914L12.2604 4.74751Z"
                            fill="var(--primary)"
                        />
                        <path
                            class="svgActive"
                            d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4261 2.61032 19.2855 2.46967C19.1448 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7249 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6317 1.16884 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1908 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5218 12.7504C22.7203 12.7466 22.9092 12.6642 23.047 12.5213C23.3348 12.2231 23.3109 11.731 23.0114 11.4445Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div class="navText bold">{{ 'ak.navigation.home' | translate }}</div>
                </a>
            </li>
            <li class="menu-list__item" *ngIf="showCabinet">
                <a
                    routerLink="/home"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M12.2604 4.74751C12.1906 4.68075 12.0978 4.64349 12.0012 4.64349C11.9046 4.64349 11.8118 4.68075 11.742 4.74751L3.1123 12.9914C3.07566 13.0265 3.0465 13.0686 3.0266 13.1153C3.0067 13.1619 2.99647 13.2121 2.99652 13.2628L2.99512 21C2.99512 21.3979 3.15315 21.7794 3.43446 22.0607C3.71576 22.342 4.09729 22.5 4.49512 22.5H8.99981C9.19872 22.5 9.38948 22.421 9.53014 22.2804C9.67079 22.1397 9.74981 21.9489 9.74981 21.75V15.375C9.74981 15.2756 9.78932 15.1802 9.85964 15.1099C9.92997 15.0395 10.0254 15 10.1248 15H13.8748C13.9743 15 14.0696 15.0395 14.14 15.1099C14.2103 15.1802 14.2498 15.2756 14.2498 15.375V21.75C14.2498 21.9489 14.3288 22.1397 14.4695 22.2804C14.6101 22.421 14.8009 22.5 14.9998 22.5H19.5026C19.9004 22.5 20.282 22.342 20.5633 22.0607C20.8446 21.7794 21.0026 21.3979 21.0026 21V13.2628C21.0027 13.2121 20.9925 13.1619 20.9726 13.1153C20.9527 13.0686 20.9235 13.0265 20.8868 12.9914L12.2604 4.74751Z"
                            fill="var(--primary)"
                        />
                        <path
                            class="svgActive"
                            d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4261 2.61032 19.2855 2.46967C19.1448 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7249 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6317 1.16884 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1908 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5218 12.7504C22.7203 12.7466 22.9092 12.6642 23.047 12.5213C23.3348 12.2231 23.3109 11.731 23.0114 11.4445Z"
                            fill="var(--primary)"
                        />
                    </svg>

                    <div class="navText bold">{{ 'cabinet.navigation.dashboard' | translate }}</div>
                </a>
            </li>

            <div
                class="navDropDown"
                *ngIf="showAK"
                [@openCloseNav]="isAKAccountOpen ? 'openNav' : 'closedNav'"
                [ngClass]="{ addedMargin: !isNotPensioner }"
            >
                <li class="menu-list__item buttonToOpenDropDownInNav">
                    <a [ngClass]="{ light: (1 | colourTheme) === false }" (click)="akAccountMenuClick()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_1675_76238)">
                                <path
                                    d="M4.875 23.25H3.375C3.07663 23.25 2.79048 23.1315 2.5795 22.9205C2.36853 22.7095 2.25 22.4234 2.25 22.125V15.375C2.25 15.0766 2.36853 14.7905 2.5795 14.5795C2.79048 14.3685 3.07663 14.25 3.375 14.25H4.875C5.17337 14.25 5.45952 14.3685 5.6705 14.5795C5.88147 14.7905 6 15.0766 6 15.375V22.125C6 22.4234 5.88147 22.7095 5.6705 22.9205C5.45952 23.1315 5.17337 23.25 4.875 23.25Z"
                                    fill="var(--primary)"
                                />
                                <path
                                    d="M15.375 23.25H13.875C13.5766 23.25 13.2905 23.1315 13.0795 22.9205C12.8685 22.7095 12.75 22.4234 12.75 22.125V10.875C12.75 10.5766 12.8685 10.2905 13.0795 10.0795C13.2905 9.86854 13.5766 9.75002 13.875 9.75002H15.375C15.6734 9.75002 15.9595 9.86854 16.1705 10.0795C16.3815 10.2905 16.5 10.5766 16.5 10.875V22.125C16.5 22.4234 16.3815 22.7095 16.1705 22.9205C15.9595 23.1315 15.6734 23.25 15.375 23.25Z"
                                    fill="var(--primary)"
                                />
                                <path
                                    d="M20.625 23.25H19.125C18.8266 23.25 18.5405 23.1315 18.3295 22.9205C18.1185 22.7095 18 22.4234 18 22.125V5.62502C18 5.32665 18.1185 5.0405 18.3295 4.82952C18.5405 4.61854 18.8266 4.50002 19.125 4.50002H20.625C20.9234 4.50002 21.2095 4.61854 21.4205 4.82952C21.6315 5.0405 21.75 5.32665 21.75 5.62502V22.125C21.75 22.4234 21.6315 22.7095 21.4205 22.9205C21.2095 23.1315 20.9234 23.25 20.625 23.25Z"
                                    fill="var(--primary)"
                                />
                                <path
                                    d="M10.125 23.25H8.625C8.32663 23.25 8.04048 23.1315 7.8295 22.9205C7.61853 22.7095 7.5 22.4234 7.5 22.125V1.87502C7.5 1.57665 7.61853 1.2905 7.8295 1.07952C8.04048 0.868542 8.32663 0.750015 8.625 0.750015H10.125C10.4234 0.750015 10.7095 0.868542 10.9205 1.07952C11.1315 1.2905 11.25 1.57665 11.25 1.87502V22.125C11.25 22.4234 11.1315 22.7095 10.9205 22.9205C10.7095 23.1315 10.4234 23.25 10.125 23.25Z"
                                    fill="var(--primary)"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1675_76238">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div class="navText bold">{{ 'ak.navigation.akAccount' | translate }}</div>
                        <svg
                            _ngcontent-jwx-c158=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="var(--primary)"
                            [ngClass]="isSimulationsOpen ? 'rotated' : ''"
                        >
                            <path
                                _ngcontent-jwx-c158=""
                                d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                                fill="#04050E"
                            ></path>
                        </svg>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/account"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'ak.navigation.accountOverview' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/account-movements"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'ak.navigation.accountMovements' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/bank-transfer"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'ak.navigation.bankTransfer' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/profile"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'ak.navigation.profile' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/fund-information"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'cabinet.navigation.employeeFundInformation' | translate }}</div>
                    </a>
                </li>
            </div>
            <div *ngIf="showCabinet" class="navDropDown" [@openCloseNav]="isPensionFundOpen ? 'openNav' : 'closedNav'">
                <li class="menu-list__item buttonToOpenDropDownInNav">
                    <a [ngClass]="{ light: (1 | colourTheme) === false }" (click)="pensionFundClick()">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="var(--primary)"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.25 4.5H6.75C6.55109 4.5 6.36032 4.42098 6.21967 4.28033C6.07902 4.13968 6 3.94891 6 3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3H17.25C17.4489 3 17.6397 3.07902 17.7803 3.21967C17.921 3.36032 18 3.55109 18 3.75C18 3.94891 17.921 4.13968 17.7803 4.28033C17.6397 4.42098 17.4489 4.5 17.25 4.5Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M18.75 6.75H5.25C5.05109 6.75 4.86032 6.67098 4.71967 6.53033C4.57902 6.38968 4.5 6.19891 4.5 6C4.5 5.80109 4.57902 5.61032 4.71967 5.46967C4.86032 5.32902 5.05109 5.25 5.25 5.25H18.75C18.9489 5.25 19.1397 5.32902 19.2803 5.46967C19.421 5.61032 19.5 5.80109 19.5 6C19.5 6.19891 19.421 6.38968 19.2803 6.53033C19.1397 6.67098 18.9489 6.75 18.75 6.75Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M19.6467 21H4.35328C3.79565 20.9994 3.26103 20.7776 2.86672 20.3833C2.47241 19.989 2.25062 19.4544 2.25 18.8967V9.60328C2.25062 9.04565 2.47241 8.51103 2.86672 8.11672C3.26103 7.72241 3.79565 7.50062 4.35328 7.5H19.6467C20.2044 7.50062 20.739 7.72241 21.1333 8.11672C21.5276 8.51103 21.7494 9.04565 21.75 9.60328V18.8967C21.7494 19.4544 21.5276 19.989 21.1333 20.3833C20.739 20.7776 20.2044 20.9994 19.6467 21Z"
                                fill="var(--primary)"
                            />
                        </svg>
                        <div class="navText bold">{{ 'cabinet.navigation.pensionFund' | translate }}</div>
                        <svg
                            _ngcontent-jwx-c158=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="var(--primary)"
                            [ngClass]="isPensionFundOpen ? 'rotated' : ''"
                        >
                            <path
                                _ngcontent-jwx-c158=""
                                d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                                fill="#04050E"
                            ></path>
                        </svg>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav my-pension">
                    <a
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        routerLink="/cabinet/my-pension/pension"
                        routerLinkActive="active"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'cabinet.navigation.myPension' | translate }}</div>
                    </a>
                </li>
                <div
                    class="navDropDown"
                    *ngIf="showCabinet"
                    [@openCloseNav]="isSimulationsOpen ? 'openNav' : 'closedNav'"
                >
                    <li class="menu-list__item buttonInDropDownNav">
                        <a
                            class="link"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                            (click)="simulationsMenuClick()"
                        >
                            <div class="navText bold">
                                {{ 'cabinet.navigation.simulations-and-applications' | translate }}
                            </div>
                            <svg
                                _ngcontent-jwx-c158=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="var(--primary)"
                                [ngClass]="isSimulationsOpen ? 'rotated' : ''"
                            >
                                <path
                                    _ngcontent-jwx-c158=""
                                    d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                                    fill="#04050E"
                                ></path>
                            </svg>
                        </a>
                    </li>
                    <li class="menu-list__item buttonInDropDownNav secondIndent">
                        <a
                                routerLink="/cabinet/my-pension/purchase"
                                routerLinkActive="active"
                                class="link"
                                (click)="menuOpen()"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <div class="navText">
                                {{ 'cabinet.navigation.purchases' | translate }}
                            </div>
                        </a>
                    </li>

                    <li *ngIf="hasWEF" class="menu-list__item buttonInDropDownNav secondIndent">
                        <a
                            routerLink="/cabinet/my-pension/simulation-encouragement-of-homeownership"
                            routerLinkActive="active"
                            class="link"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                            (click)="menuOpen()"
                        >
                            <div class="navText">
                                {{ 'cabinet.navigation.submenu.simulations.homeOwnershipPromotion' | translate }}
                            </div>
                        </a>
                    </li>
                    <!-- <li class="menu-list__item buttonInDropDownNav">
                    <a routerLink="/cabinet/my-pension/savings-plan-change" routerLinkActive="active" class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }" (click)="menuOpen()">
                        <svg _ngcontent-spt-c232="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <path class="svgActiveInDropDown" _ngcontent-spt-c232=""
                                d="M2.25 15.75L21.75 15.75C22.1642 15.75 22.5 15.4142 22.5 15V4.5C22.5 4.08579 22.1642 3.75 21.75 3.75L2.25 3.75C1.83579 3.75 1.5 4.08579 1.5 4.5V15C1.5 15.4142 1.83579 15.75 2.25 15.75Z"
                                stroke="var(--primary)" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown" d="M3 18H21" stroke="var(--primary)"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown" d="M4.5 20.25H19.5" stroke="var(--primary)"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M12 13.5C14.0711 13.5 15.75 11.8211 15.75 9.75C15.75 7.67893 14.0711 6 12 6C9.92893 6 8.25 7.67893 8.25 9.75C8.25 11.8211 9.92893 13.5 12 13.5Z"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M22.5 7.5C21.5054 7.5 20.5516 7.10491 19.8483 6.40165C19.1451 5.69839 18.75 4.74456 18.75 3.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M1.5 7.5C1.99246 7.5 2.48009 7.403 2.93506 7.21455C3.39003 7.02609 3.80343 6.74987 4.15165 6.40165C4.49987 6.05343 4.77609 5.64003 4.96455 5.18506C5.153 4.73009 5.25 4.24246 5.25 3.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M22.5 12C21.5054 12 20.5516 12.3951 19.8483 13.0983C19.1451 13.8016 18.75 14.7554 18.75 15.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M1.5 12C1.99246 12 2.48009 12.097 2.93506 12.2855C3.39003 12.4739 3.80343 12.7501 4.15165 13.0983C4.49987 13.4466 4.77609 13.86 4.96455 14.3149C5.153 14.7699 5.25 15.2575 5.25 15.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <div class="navText">{{ 'cabinet.navigation.submenu.simulations.savingsPlanChange' | translate
                            }}</div>
                    </a>
                </li> -->
                    <li class="menu-list__item buttonInDropDownNav secondIndent">
                        <a
                            routerLink="/cabinet/my-pension/withdrawal"
                            routerLinkActive="active"
                            class="link"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                            (click)="menuOpen()"
                        >
                            <div class="navText">
                                {{ 'cabinet.navigation.submenu.simulations.terminationBenefits' | translate }}
                            </div>
                        </a>
                    </li>
                    <li class="menu-list__item buttonInDropDownNav secondIndent">
                        <a
                                routerLink="/cabinet/my-pension/simulation"
                                routerLinkActive="active"
                                class="link"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                                (click)="menuOpen()"
                        >
                            <div class="navText">
                                {{ 'cabinet.navigation.submenu.simulations.retirementSimulation' | translate }}
                            </div>
                        </a>
                    </li>
                </div>
                <li class="menu-list__item buttonInDropDownNav" *ngIf="showCabinet">
                    <a
                        class="link"
                        routerLink="/cabinet/profile"
                        routerLinkActive="active"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'cabinet.navigation.profile' | translate }}</div>
                    </a>
                </li>
                <!-- <li class="menu-list__item">
                  <a routerLink="/cabinet/wage" routerLinkActive="active" [ngClass]="{ light: (1 | colourTheme) === false }">
                      {{ 'cabinet.navigation.wage' | translate }}
                  </a>
              </li> -->
                <li class="menu-list__item buttonInDropDownNav" *ngIf="showCabinet">
                    <a
                        class="link"
                        routerLink="/cabinet/pension-fund-info"
                        routerLinkActive="active"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        (click)="menuOpen()"
                    >
                        <div class="navText">{{ 'cabinet.navigation.pensionFundInformation' | translate }}</div>
                    </a>
                </li>
            </div>
            <li class="menu-list__item">
                <a
                    routerLink="/my-documents"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M20.0625 10.5H13.5C12.9033 10.5 12.331 10.2629 11.909 9.84099C11.4871 9.41903 11.25 8.84674 11.25 8.25V1.6875C11.25 1.63777 11.2302 1.59008 11.1951 1.55492C11.1599 1.51975 11.1122 1.5 11.0625 1.5H6.75C5.95435 1.5 5.19129 1.81607 4.62868 2.37868C4.06607 2.94129 3.75 3.70435 3.75 4.5V19.5C3.75 20.2956 4.06607 21.0587 4.62868 21.6213C5.19129 22.1839 5.95435 22.5 6.75 22.5H17.25C18.0456 22.5 18.8087 22.1839 19.3713 21.6213C19.9339 21.0587 20.25 20.2956 20.25 19.5V10.6875C20.25 10.6378 20.2302 10.5901 20.1951 10.5549C20.1599 10.5198 20.1122 10.5 20.0625 10.5ZM15.75 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H15.75C15.9489 16.5 16.1397 16.579 16.2803 16.7197C16.421 16.8603 16.5 17.0511 16.5 17.25C16.5 17.4489 16.421 17.6397 16.2803 17.7803C16.1397 17.921 15.9489 18 15.75 18ZM15.75 14.25H8.25C8.05109 14.25 7.86032 14.171 7.71967 14.0303C7.57902 13.8897 7.5 13.6989 7.5 13.5C7.5 13.3011 7.57902 13.1103 7.71967 12.9697C7.86032 12.829 8.05109 12.75 8.25 12.75H15.75C15.9489 12.75 16.1397 12.829 16.2803 12.9697C16.421 13.1103 16.5 13.3011 16.5 13.5C16.5 13.6989 16.421 13.8897 16.2803 14.0303C16.1397 14.171 15.9489 14.25 15.75 14.25Z"
                            fill="var(--primary)"
                        />
                        <path
                            class="svgActive"
                            d="M19.6509 8.84015L12.9098 2.09906C12.8967 2.08602 12.8801 2.07716 12.8619 2.07357C12.8438 2.06999 12.825 2.07184 12.8079 2.07891C12.7908 2.08597 12.7762 2.09793 12.7659 2.11327C12.7556 2.12862 12.7501 2.14666 12.75 2.16515V8.24999C12.75 8.4489 12.829 8.63967 12.9697 8.78032C13.1103 8.92098 13.3011 8.99999 13.5 8.99999H19.5848C19.6033 8.99992 19.6214 8.99438 19.6367 8.98407C19.6521 8.97376 19.664 8.95915 19.6711 8.94207C19.6781 8.92498 19.68 8.9062 19.6764 8.88806C19.6728 8.86993 19.664 8.85326 19.6509 8.84015Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div class="navText bold">{{ 'cabinet.navigation.myDocuments' | translate }}</div>
                </a>
            </li>
            <li class="menu-list__item" *ngIf="hasNews">
                <a
                    routerLink="news"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="var(--primary)"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            class="svgActive"
                            d="M9.48 3.46889C7.7864 2.66029 5.41922 2.2642 2.25 2.25014C1.95111 2.24607 1.65809 2.33323 1.41 2.49998C1.20637 2.63762 1.03968 2.82319 0.924597 3.04036C0.809512 3.25754 0.749555 3.49966 0.750002 3.74545V17.1564C0.750002 18.063 1.395 18.7469 2.25 18.7469C5.58141 18.7469 8.92312 19.0581 10.9247 20.95C10.9521 20.976 10.9865 20.9934 11.0237 21C11.0608 21.0066 11.0991 21.0021 11.1338 20.9871C11.1684 20.9721 11.1979 20.9473 11.2186 20.9157C11.2393 20.8841 11.2502 20.8471 11.25 20.8094V5.00732C11.2501 4.90071 11.2273 4.79532 11.1831 4.69828C11.139 4.60124 11.0745 4.5148 10.9941 4.44482C10.5356 4.05287 10.0263 3.7246 9.48 3.46889Z"
                            fill="#646567"
                        />
                        <path
                            class="svgActive"
                            d="M22.59 2.49861C22.3418 2.33227 22.0487 2.24561 21.75 2.25017C18.5808 2.26423 16.2136 2.65845 14.52 3.46892C13.9737 3.72417 13.4643 4.0518 13.0055 4.44298C12.9252 4.51309 12.8609 4.59955 12.8168 4.69658C12.7728 4.7936 12.75 4.89893 12.75 5.00548V20.8085C12.75 20.8447 12.7607 20.8802 12.7807 20.9104C12.8008 20.9407 12.8293 20.9643 12.8628 20.9784C12.8962 20.9924 12.9331 20.9963 12.9687 20.9895C13.0044 20.9827 13.0372 20.9656 13.0631 20.9402C14.2664 19.7449 16.3781 18.7455 21.7519 18.746C22.1497 18.746 22.5312 18.5879 22.8125 18.3066C23.0938 18.0253 23.2519 17.6438 23.2519 17.246V3.74595C23.2524 3.49968 23.1923 3.25707 23.0769 3.03953C22.9614 2.82199 22.7942 2.63621 22.59 2.49861Z"
                            fill="#646567"
                        />
                    </svg>

                    <div class="navText bold">{{ 'cabinet.navigation.news' | translate }}</div>
                </a>
            </li>
            <li class="menu-list__item" *ngIf="isEmployer && hasCMS">
                <a
                    routerLink="/content-management"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="var(&#45;&#45;primary)"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            class="svgActive"
                            d="M9.48 3.46889C7.7864 2.66029 5.41922 2.2642 2.25 2.25014C1.95111 2.24607 1.65809 2.33323 1.41 2.49998C1.20637 2.63762 1.03968 2.82319 0.924597 3.04036C0.809512 3.25754 0.749555 3.49966 0.750002 3.74545V17.1564C0.750002 18.063 1.395 18.7469 2.25 18.7469C5.58141 18.7469 8.92312 19.0581 10.9247 20.95C10.9521 20.976 10.9865 20.9934 11.0237 21C11.0608 21.0066 11.0991 21.0021 11.1338 20.9871C11.1684 20.9721 11.1979 20.9473 11.2186 20.9157C11.2393 20.8841 11.2502 20.8471 11.25 20.8094V5.00732C11.2501 4.90071 11.2273 4.79532 11.1831 4.69828C11.139 4.60124 11.0745 4.5148 10.9941 4.44482C10.5356 4.05287 10.0263 3.7246 9.48 3.46889Z"
                            fill="#646567"
                        />
                        <path
                            class="svgActive"
                            d="M22.59 2.49861C22.3418 2.33227 22.0487 2.24561 21.75 2.25017C18.5808 2.26423 16.2136 2.65845 14.52 3.46892C13.9737 3.72417 13.4643 4.0518 13.0055 4.44298C12.9252 4.51309 12.8609 4.59955 12.8168 4.69658C12.7728 4.7936 12.75 4.89893 12.75 5.00548V20.8085C12.75 20.8447 12.7607 20.8802 12.7807 20.9104C12.8008 20.9407 12.8293 20.9643 12.8628 20.9784C12.8962 20.9924 12.9331 20.9963 12.9687 20.9895C13.0044 20.9827 13.0372 20.9656 13.0631 20.9402C14.2664 19.7449 16.3781 18.7455 21.7519 18.746C22.1497 18.746 22.5312 18.5879 22.8125 18.3066C23.0938 18.0253 23.2519 17.6438 23.2519 17.246V3.74595C23.2524 3.49968 23.1923 3.25707 23.0769 3.03953C22.9614 2.82199 22.7942 2.63621 22.59 2.49861Z"
                            fill="#646567"
                        />
                    </svg>

                    <div class="navText bold">{{ 'cabinet.navigation.content-management' | translate }}</div>
                </a>
            </li>

            <div class="menuColapseButton">
                <li class="menu-list__item">
                    <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                class="svgActive"
                                d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                                fill="var(--primary)"
                            />
                        </svg>
                        <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
                    </a>
                </li>
            </div>
            <div class="version">Build: {{ version.semverString }} ({{ version.date }})</div>
        </ul>
    </div>
    <div class="nav-menu">
        <div class="menuColapseButtonTablet">
            <li class="menu-list__item">
                <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
                </a>
            </li>
        </div>
        <div class="companySelectionContainer">
            <div *ngIf="logoUrl" class="logo custom-logo">
                <img class="logo-img" [src]="logoUrl" alt="logo" />
            </div>
            <div *ngIf="!logoUrl" class="logo">
                <img src="../../../../../assets/img/clanLogo/swarm_logo_svg.png" alt="logo" />
            </div>
            <!-- <div class="dropDown" >
              <mat-form-field appearance="standard">
                  <mat-label>{{ 'cabinet.companies' | translate}}</mat-label>
                  <mat-select [formControl]="companyForm" multiple>
                    <mat-select-trigger>
                      <span *ngIf="(companyForm.value?.length || 0) > 1" class="example-additional-selection">
                        (+{{(companyForm.value?.length || 0) - 1}} {{companyForm.value?.length === 2 ? 'other' : 'others'}})
                      </span> 
                    </mat-select-trigger>
                      <mat-option (onSelectionChange)="changeCompany($event)" *ngFor="let trinity of trinities" [value]="trinity"></mat-option>
                  </mat-select>
              </mat-form-field>
              </div> -->
            <!--  <div class="dropDown">
                <span>{{ selectedCompany }}</span>
                <svg class="companySelectionArrow" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="var(--primary)">
                  <path d="M2.11997 1.29L5.99997 5.17L9.87997 1.29C10.27 0.899998 10.9 0.899998 11.29 1.29C11.68 1.68 11.68 2.31 11.29 2.7L6.69997 7.29C6.30997 7.68 5.67997 7.68 5.28997 7.29L0.699971 2.7C0.309971 2.31 0.309971 1.68 0.699971 1.29C1.08997 0.909998 1.72997 0.899998 2.11997 1.29Z" fill="#04050E"/>
                </svg>
                <div class="dropDown-content">
                  <div class="companySelection" *ngFor="let company of companys">
                    <p class="companyObject" (click)="selectCompany(company.company)">{{company.company}}</p>
                  </div>
                    
                </div>
              </div> -->
        </div>
        <ul class="nav-menu__list menu-list">
            <!-- <li class="menu-list__item">
                    <a
                        routerLink="/cabinet/dashboard"
                        routerLinkActive="active"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        {{ 'cabinet.navigation.dashboard' | translate }}
                    </a>
                </li> -->
            <li class="menu-list__item firstIndent" *ngIf="!showCabinet">
                <a routerLink="/home" routerLinkActive="active" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M12.2604 4.74751C12.1906 4.68075 12.0978 4.64349 12.0012 4.64349C11.9046 4.64349 11.8118 4.68075 11.742 4.74751L3.1123 12.9914C3.07566 13.0265 3.0465 13.0686 3.0266 13.1153C3.0067 13.1619 2.99647 13.2121 2.99652 13.2628L2.99512 21C2.99512 21.3979 3.15315 21.7794 3.43446 22.0607C3.71576 22.342 4.09729 22.5 4.49512 22.5H8.99981C9.19872 22.5 9.38948 22.421 9.53014 22.2804C9.67079 22.1397 9.74981 21.9489 9.74981 21.75V15.375C9.74981 15.2756 9.78932 15.1802 9.85964 15.1099C9.92997 15.0395 10.0254 15 10.1248 15H13.8748C13.9743 15 14.0696 15.0395 14.14 15.1099C14.2103 15.1802 14.2498 15.2756 14.2498 15.375V21.75C14.2498 21.9489 14.3288 22.1397 14.4695 22.2804C14.6101 22.421 14.8009 22.5 14.9998 22.5H19.5026C19.9004 22.5 20.282 22.342 20.5633 22.0607C20.8446 21.7794 21.0026 21.3979 21.0026 21V13.2628C21.0027 13.2121 20.9925 13.1619 20.9726 13.1153C20.9527 13.0686 20.9235 13.0265 20.8868 12.9914L12.2604 4.74751Z"
                            fill="var(--primary)"
                        />
                        <path
                            class="svgActive"
                            d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4261 2.61032 19.2855 2.46967C19.1448 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7249 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6317 1.16884 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1908 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5218 12.7504C22.7203 12.7466 22.9092 12.6642 23.047 12.5213C23.3348 12.2231 23.3109 11.731 23.0114 11.4445Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div *ngIf="isOpen" class="navText bold">{{ 'ak.navigation.home' | translate }}</div>
                </a>
            </li>
            <li class="menu-list__item firstIndent" *ngIf="showCabinet">
                <a routerLink="/home" routerLinkActive="active" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M12.2604 4.74751C12.1906 4.68075 12.0978 4.64349 12.0012 4.64349C11.9046 4.64349 11.8118 4.68075 11.742 4.74751L3.1123 12.9914C3.07566 13.0265 3.0465 13.0686 3.0266 13.1153C3.0067 13.1619 2.99647 13.2121 2.99652 13.2628L2.99512 21C2.99512 21.3979 3.15315 21.7794 3.43446 22.0607C3.71576 22.342 4.09729 22.5 4.49512 22.5H8.99981C9.19872 22.5 9.38948 22.421 9.53014 22.2804C9.67079 22.1397 9.74981 21.9489 9.74981 21.75V15.375C9.74981 15.2756 9.78932 15.1802 9.85964 15.1099C9.92997 15.0395 10.0254 15 10.1248 15H13.8748C13.9743 15 14.0696 15.0395 14.14 15.1099C14.2103 15.1802 14.2498 15.2756 14.2498 15.375V21.75C14.2498 21.9489 14.3288 22.1397 14.4695 22.2804C14.6101 22.421 14.8009 22.5 14.9998 22.5H19.5026C19.9004 22.5 20.282 22.342 20.5633 22.0607C20.8446 21.7794 21.0026 21.3979 21.0026 21V13.2628C21.0027 13.2121 20.9925 13.1619 20.9726 13.1153C20.9527 13.0686 20.9235 13.0265 20.8868 12.9914L12.2604 4.74751Z"
                            fill="var(--primary)"
                        />
                        <path
                            class="svgActive"
                            d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4261 2.61032 19.2855 2.46967C19.1448 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7249 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6317 1.16884 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1908 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5218 12.7504C22.7203 12.7466 22.9092 12.6642 23.047 12.5213C23.3348 12.2231 23.3109 11.731 23.0114 11.4445Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div *ngIf="isOpen" class="navText bold">{{ 'cabinet.navigation.dashboard' | translate }}</div>
                </a>
            </li>

            <!-- <li class="menu-list__item">
                    <a
                        routerLink="/cabinet/my-pension"
                        routerLinkActive="active"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path class="svgActive" d="M22.2028 9.84374C21.5466 9.35717 20.7403 8.99999 19.8891 8.80733C19.7493 8.77589 19.6214 8.70533 19.5202 8.60391C19.419 8.50248 19.3488 8.37436 19.3177 8.23452C18.952 6.60561 18.165 5.20874 17.0081 4.1503C15.6633 2.91796 13.8848 2.24014 12 2.24014C10.343 2.24014 8.8125 2.75952 7.57641 3.74249C6.73867 4.40973 6.06434 5.25945 5.60484 6.22686C5.55503 6.33271 5.48114 6.42544 5.38908 6.49763C5.29703 6.56983 5.18936 6.61949 5.07469 6.64264C3.82078 6.89577 2.68875 7.43342 1.82437 8.19514C0.630937 9.24983 0 10.6519 0 12.2526C0 13.8708 0.679219 15.3464 1.91203 16.4133C3.08953 17.43 4.67484 17.9901 6.375 17.9901H11.25V10.8009L9.53016 12.5208C9.45835 12.5926 9.37272 12.649 9.27845 12.6867C9.18418 12.7245 9.08323 12.7426 8.98172 12.7402C8.88021 12.7377 8.78026 12.7147 8.68792 12.6725C8.59558 12.6302 8.51278 12.5697 8.44453 12.4945C8.17266 12.1959 8.19891 11.7305 8.48438 11.445L11.4698 8.45999C11.6105 8.31944 11.8012 8.24049 12 8.24049C12.1988 8.24049 12.3895 8.31944 12.5302 8.45999L15.5156 11.444C15.81 11.7389 15.8269 12.2222 15.5339 12.518C15.4644 12.5882 15.3817 12.6439 15.2906 12.6821C15.1995 12.7202 15.1017 12.74 15.0029 12.7403C14.9041 12.7405 14.8063 12.7213 14.715 12.6836C14.6236 12.6459 14.5407 12.5906 14.4708 12.5208L12.75 10.8009V17.9901H18.5625C20.0316 17.9901 21.3708 17.5776 22.3336 16.829C23.4239 15.9806 24 14.7665 24 13.3214C24 11.918 23.3784 10.7147 22.2028 9.84374Z" fill="var(--primary)"/>
                        <path class="svgActive" d="M11.25 21.0098C11.25 21.2088 11.329 21.3995 11.4697 21.5402C11.6103 21.6808 11.8011 21.7598 12 21.7598C12.1989 21.7598 12.3897 21.6808 12.5303 21.5402C12.671 21.3995 12.75 21.2088 12.75 21.0098V17.9902H11.25V21.0098Z" fill="var(--primary)"/>
                        </svg>
                        <div class="navText">{{ 'cabinet.navigation.myPension' | translate }}</div>
                    </a>
                </li> -->
            <div
                class="navDropDown firstIndent"
                *ngIf="showAK"
                [@openCloseNav]="isAKAccountOpen ? 'openNav' : 'closedNav'"
            >
                <li class="menu-list__item buttonToOpenDropDownInNav">
                    <a [ngClass]="{ light: (1 | colourTheme) === false }" (click)="akAccountMenuClick()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_1675_76238)">
                                <path
                                    d="M4.875 23.25H3.375C3.07663 23.25 2.79048 23.1315 2.5795 22.9205C2.36853 22.7095 2.25 22.4234 2.25 22.125V15.375C2.25 15.0766 2.36853 14.7905 2.5795 14.5795C2.79048 14.3685 3.07663 14.25 3.375 14.25H4.875C5.17337 14.25 5.45952 14.3685 5.6705 14.5795C5.88147 14.7905 6 15.0766 6 15.375V22.125C6 22.4234 5.88147 22.7095 5.6705 22.9205C5.45952 23.1315 5.17337 23.25 4.875 23.25Z"
                                    fill="var(--primary)"
                                />
                                <path
                                    d="M15.375 23.25H13.875C13.5766 23.25 13.2905 23.1315 13.0795 22.9205C12.8685 22.7095 12.75 22.4234 12.75 22.125V10.875C12.75 10.5766 12.8685 10.2905 13.0795 10.0795C13.2905 9.86854 13.5766 9.75002 13.875 9.75002H15.375C15.6734 9.75002 15.9595 9.86854 16.1705 10.0795C16.3815 10.2905 16.5 10.5766 16.5 10.875V22.125C16.5 22.4234 16.3815 22.7095 16.1705 22.9205C15.9595 23.1315 15.6734 23.25 15.375 23.25Z"
                                    fill="var(--primary)"
                                />
                                <path
                                    d="M20.625 23.25H19.125C18.8266 23.25 18.5405 23.1315 18.3295 22.9205C18.1185 22.7095 18 22.4234 18 22.125V5.62502C18 5.32665 18.1185 5.0405 18.3295 4.82952C18.5405 4.61854 18.8266 4.50002 19.125 4.50002H20.625C20.9234 4.50002 21.2095 4.61854 21.4205 4.82952C21.6315 5.0405 21.75 5.32665 21.75 5.62502V22.125C21.75 22.4234 21.6315 22.7095 21.4205 22.9205C21.2095 23.1315 20.9234 23.25 20.625 23.25Z"
                                    fill="var(--primary)"
                                />
                                <path
                                    d="M10.125 23.25H8.625C8.32663 23.25 8.04048 23.1315 7.8295 22.9205C7.61853 22.7095 7.5 22.4234 7.5 22.125V1.87502C7.5 1.57665 7.61853 1.2905 7.8295 1.07952C8.04048 0.868542 8.32663 0.750015 8.625 0.750015H10.125C10.4234 0.750015 10.7095 0.868542 10.9205 1.07952C11.1315 1.2905 11.25 1.57665 11.25 1.87502V22.125C11.25 22.4234 11.1315 22.7095 10.9205 22.9205C10.7095 23.1315 10.4234 23.25 10.125 23.25Z"
                                    fill="var(--primary)"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1675_76238">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div *ngIf="isOpen" class="navText bold">{{ 'ak.navigation.akAccount' | translate }}</div>
                        <svg
                            *ngIf="isOpen"
                            _ngcontent-jwx-c158=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="var(--primary)"
                            [ngClass]="isAKAccountOpen ? 'rotated' : ''"
                        >
                            <path
                                _ngcontent-jwx-c158=""
                                d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                                fill="#04050E"
                            ></path>
                        </svg>
                    </a>
                </li>

                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/account"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div *ngIf="isOpen" class="navText">{{ 'ak.navigation.accountOverview' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/account-movements"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div *ngIf="isOpen" class="navText">{{ 'ak.navigation.accountMovements' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/bank-transfer"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div class="navText">{{ 'ak.navigation.bankTransfer' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/profile"
                        routerLinkActive="active"
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div *ngIf="isOpen" class="navText">{{ 'ak.navigation.profile' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        routerLink="/ak/fund-information"
                        routerLinkActive="active"
                        class="link balanceWrap"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div *ngIf="isOpen" class="navText">
                            {{ 'cabinet.navigation.employeeFundInformation' | translate }}
                        </div>
                    </a>
                </li>
            </div>
            <div
                class="navDropDown firstIndent"
                *ngIf="isNotPensioner && showCabinet"
                [@openCloseNav]="isPensionFundOpen ? 'openNav' : 'closedNav'"
            >
                <li class="menu-list__item buttonToOpenDropDownInNav">
                    <a [ngClass]="{ light: (1 | colourTheme) === false }" (click)="pensionFundClick()">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="var(--primary)"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.25 4.5H6.75C6.55109 4.5 6.36032 4.42098 6.21967 4.28033C6.07902 4.13968 6 3.94891 6 3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3H17.25C17.4489 3 17.6397 3.07902 17.7803 3.21967C17.921 3.36032 18 3.55109 18 3.75C18 3.94891 17.921 4.13968 17.7803 4.28033C17.6397 4.42098 17.4489 4.5 17.25 4.5Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M18.75 6.75H5.25C5.05109 6.75 4.86032 6.67098 4.71967 6.53033C4.57902 6.38968 4.5 6.19891 4.5 6C4.5 5.80109 4.57902 5.61032 4.71967 5.46967C4.86032 5.32902 5.05109 5.25 5.25 5.25H18.75C18.9489 5.25 19.1397 5.32902 19.2803 5.46967C19.421 5.61032 19.5 5.80109 19.5 6C19.5 6.19891 19.421 6.38968 19.2803 6.53033C19.1397 6.67098 18.9489 6.75 18.75 6.75Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M19.6467 21H4.35328C3.79565 20.9994 3.26103 20.7776 2.86672 20.3833C2.47241 19.989 2.25062 19.4544 2.25 18.8967V9.60328C2.25062 9.04565 2.47241 8.51103 2.86672 8.11672C3.26103 7.72241 3.79565 7.50062 4.35328 7.5H19.6467C20.2044 7.50062 20.739 7.72241 21.1333 8.11672C21.5276 8.51103 21.7494 9.04565 21.75 9.60328V18.8967C21.7494 19.4544 21.5276 19.989 21.1333 20.3833C20.739 20.7776 20.2044 20.9994 19.6467 21Z"
                                fill="var(--primary)"
                            />
                        </svg>
                        <div class="navText bold">{{ 'cabinet.navigation.pensionFund' | translate }}</div>
                        <svg
                            _ngcontent-jwx-c158=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="var(--primary)"
                            [ngClass]="isPensionFundOpen ? 'rotated' : ''"
                        >
                            <path
                                _ngcontent-jwx-c158=""
                                d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                                fill="#04050E"
                            ></path>
                        </svg>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav">
                    <a
                        class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        routerLink="/cabinet/my-pension/pension"
                        routerLinkActive="active"
                    >
                        <div class="navText">{{ 'cabinet.navigation.myPension' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonToOpenDropDownInNav">
                    <div
                        class="navDropDown"
                        *ngIf="showCabinet"
                        [@openCloseNav]="isSimulationsOpen ? 'openNav' : 'closedNav'"
                    >
                        <li class="menu-list__item buttonToOpenDropDownInNav buttonInDropDownNav">
                            <a
                                class="link"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                                (click)="simulationsMenuClick()"
                            >
                                <div class="navText bold">
                                    {{ 'cabinet.navigation.simulations-and-applications' | translate }}
                                </div>
                                <svg
                                    _ngcontent-jwx-c158=""
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="8"
                                    viewBox="0 0 12 8"
                                    fill="var(--primary)"
                                    [ngClass]="isSimulationsOpen ? 'rotated' : ''"
                                >
                                    <path
                                        _ngcontent-jwx-c158=""
                                        d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                                        fill="#04050E"
                                    ></path>
                                </svg>
                            </a>
                        </li>

                        <li class="menu-list__item buttonInDropDownNav secondIndent">
                            <a
                                    routerLink="/cabinet/my-pension/purchase"
                                    routerLinkActive="active"
                                    class="link"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div class="navText">
                                    {{ 'cabinet.navigation.purchases' | translate }}
                                </div>
                            </a>
                        </li>

                        <li *ngIf="hasWEF" class="menu-list__item buttonInDropDownNav secondIndent">
                            <a
                                routerLink="/cabinet/my-pension/simulation-encouragement-of-homeownership"
                                routerLinkActive="active"
                                class="link"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div *ngIf="isOpen" class="navText" style="white-space: normal">
                                    {{ 'cabinet.navigation.submenu.simulations.homeOwnershipPromotion' | translate }}
                                </div>
                            </a>
                        </li>
                        <!-- <li class="menu-list__item buttonInDropDownNav">
                    <a routerLink="/cabinet/my-pension/savings-plan-change" routerLinkActive="active" class="link"
                        [ngClass]="{ light: (1 | colourTheme) === false }">
                        <svg _ngcontent-spt-c232="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <path class="svgActiveInDropDown" _ngcontent-spt-c232=""
                                d="M2.25 15.75L21.75 15.75C22.1642 15.75 22.5 15.4142 22.5 15V4.5C22.5 4.08579 22.1642 3.75 21.75 3.75L2.25 3.75C1.83579 3.75 1.5 4.08579 1.5 4.5V15C1.5 15.4142 1.83579 15.75 2.25 15.75Z"
                                stroke="var(--primary)" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown" d="M3 18H21" stroke="var(--primary)"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown" d="M4.5 20.25H19.5" stroke="var(--primary)"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M12 13.5C14.0711 13.5 15.75 11.8211 15.75 9.75C15.75 7.67893 14.0711 6 12 6C9.92893 6 8.25 7.67893 8.25 9.75C8.25 11.8211 9.92893 13.5 12 13.5Z"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M22.5 7.5C21.5054 7.5 20.5516 7.10491 19.8483 6.40165C19.1451 5.69839 18.75 4.74456 18.75 3.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M1.5 7.5C1.99246 7.5 2.48009 7.403 2.93506 7.21455C3.39003 7.02609 3.80343 6.74987 4.15165 6.40165C4.49987 6.05343 4.77609 5.64003 4.96455 5.18506C5.153 4.73009 5.25 4.24246 5.25 3.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M22.5 12C21.5054 12 20.5516 12.3951 19.8483 13.0983C19.1451 13.8016 18.75 14.7554 18.75 15.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svgActiveInDropDown"
                                d="M1.5 12C1.99246 12 2.48009 12.097 2.93506 12.2855C3.39003 12.4739 3.80343 12.7501 4.15165 13.0983C4.49987 13.4466 4.77609 13.86 4.96455 14.3149C5.153 14.7699 5.25 15.2575 5.25 15.75"
                                stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <div *ngIf="isOpen" class="navText">{{
                            'cabinet.navigation.submenu.simulations.savingsPlanChange' | translate }}</div>
                    </a>
                </li> -->
                        <li class="menu-list__item buttonInDropDownNav secondIndent">
                            <a
                                routerLink="/cabinet/my-pension/withdrawal"
                                routerLinkActive="active"
                                class="link"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div *ngIf="isOpen" class="navText">
                                    {{ 'cabinet.navigation.submenu.simulations.terminationBenefits' | translate }}
                                </div>
                            </a>
                        </li>


                        <li class="menu-list__item buttonInDropDownNav secondIndent">
                            <a
                                    routerLink="/cabinet/my-pension/simulation"
                                    routerLinkActive="active"
                                    class="link"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div *ngIf="isOpen" class="navText" style="white-space: normal">
                                    {{ 'cabinet.navigation.submenu.simulations.retirementSimulation' | translate }}
                                </div>
                            </a>
                        </li>
                    </div>
                </li>

                <li class="menu-list__item buttonInDropDownNav" *ngIf="showCabinet">
                    <a
                        class="link"
                        routerLink="/cabinet/profile"
                        routerLinkActive="active"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div class="navText">{{ 'cabinet.navigation.profile' | translate }}</div>
                    </a>
                </li>
                <li class="menu-list__item buttonInDropDownNav" *ngIf="showCabinet">
                    <a
                        class="link"
                        routerLink="/cabinet/pension-fund-info"
                        routerLinkActive="active"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div class="navText">{{ 'cabinet.navigation.pensionFundInformation' | translate }}</div>
                    </a>
                </li>
            </div>
            <li class="menu-list__item firstIndent">
                <a
                    routerLink="/my-documents"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M20.0625 10.5H13.5C12.9033 10.5 12.331 10.2629 11.909 9.84099C11.4871 9.41903 11.25 8.84674 11.25 8.25V1.6875C11.25 1.63777 11.2302 1.59008 11.1951 1.55492C11.1599 1.51975 11.1122 1.5 11.0625 1.5H6.75C5.95435 1.5 5.19129 1.81607 4.62868 2.37868C4.06607 2.94129 3.75 3.70435 3.75 4.5V19.5C3.75 20.2956 4.06607 21.0587 4.62868 21.6213C5.19129 22.1839 5.95435 22.5 6.75 22.5H17.25C18.0456 22.5 18.8087 22.1839 19.3713 21.6213C19.9339 21.0587 20.25 20.2956 20.25 19.5V10.6875C20.25 10.6378 20.2302 10.5901 20.1951 10.5549C20.1599 10.5198 20.1122 10.5 20.0625 10.5ZM15.75 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H15.75C15.9489 16.5 16.1397 16.579 16.2803 16.7197C16.421 16.8603 16.5 17.0511 16.5 17.25C16.5 17.4489 16.421 17.6397 16.2803 17.7803C16.1397 17.921 15.9489 18 15.75 18ZM15.75 14.25H8.25C8.05109 14.25 7.86032 14.171 7.71967 14.0303C7.57902 13.8897 7.5 13.6989 7.5 13.5C7.5 13.3011 7.57902 13.1103 7.71967 12.9697C7.86032 12.829 8.05109 12.75 8.25 12.75H15.75C15.9489 12.75 16.1397 12.829 16.2803 12.9697C16.421 13.1103 16.5 13.3011 16.5 13.5C16.5 13.6989 16.421 13.8897 16.2803 14.0303C16.1397 14.171 15.9489 14.25 15.75 14.25Z"
                            fill="var(--primary)"
                        />
                        <path
                            class="svgActive"
                            d="M19.6509 8.84015L12.9098 2.09906C12.8967 2.08602 12.8801 2.07716 12.8619 2.07357C12.8438 2.06999 12.825 2.07184 12.8079 2.07891C12.7908 2.08597 12.7762 2.09793 12.7659 2.11327C12.7556 2.12862 12.7501 2.14666 12.75 2.16515V8.24999C12.75 8.4489 12.829 8.63967 12.9697 8.78032C13.1103 8.92098 13.3011 8.99999 13.5 8.99999H19.5848C19.6033 8.99992 19.6214 8.99438 19.6367 8.98407C19.6521 8.97376 19.664 8.95915 19.6711 8.94207C19.6781 8.92498 19.68 8.9062 19.6764 8.88806C19.6728 8.86993 19.664 8.85326 19.6509 8.84015Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div *ngIf="isOpen" class="navText bold">{{ 'cabinet.navigation.myDocuments' | translate }}</div>
                </a>
            </li>

            <li class="menu-list__item firstIndent" *ngIf="hasNews">
                <a routerLink="news" routerLinkActive="active" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="var(--primary)"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            class="svgActive"
                            d="M9.48 3.46889C7.7864 2.66029 5.41922 2.2642 2.25 2.25014C1.95111 2.24607 1.65809 2.33323 1.41 2.49998C1.20637 2.63762 1.03968 2.82319 0.924597 3.04036C0.809512 3.25754 0.749555 3.49966 0.750002 3.74545V17.1564C0.750002 18.063 1.395 18.7469 2.25 18.7469C5.58141 18.7469 8.92312 19.0581 10.9247 20.95C10.9521 20.976 10.9865 20.9934 11.0237 21C11.0608 21.0066 11.0991 21.0021 11.1338 20.9871C11.1684 20.9721 11.1979 20.9473 11.2186 20.9157C11.2393 20.8841 11.2502 20.8471 11.25 20.8094V5.00732C11.2501 4.90071 11.2273 4.79532 11.1831 4.69828C11.139 4.60124 11.0745 4.5148 10.9941 4.44482C10.5356 4.05287 10.0263 3.7246 9.48 3.46889Z"
                            fill="#646567"
                        />
                        <path
                            class="svgActive"
                            d="M22.59 2.49861C22.3418 2.33227 22.0487 2.24561 21.75 2.25017C18.5808 2.26423 16.2136 2.65845 14.52 3.46892C13.9737 3.72417 13.4643 4.0518 13.0055 4.44298C12.9252 4.51309 12.8609 4.59955 12.8168 4.69658C12.7728 4.7936 12.75 4.89893 12.75 5.00548V20.8085C12.75 20.8447 12.7607 20.8802 12.7807 20.9104C12.8008 20.9407 12.8293 20.9643 12.8628 20.9784C12.8962 20.9924 12.9331 20.9963 12.9687 20.9895C13.0044 20.9827 13.0372 20.9656 13.0631 20.9402C14.2664 19.7449 16.3781 18.7455 21.7519 18.746C22.1497 18.746 22.5312 18.5879 22.8125 18.3066C23.0938 18.0253 23.2519 17.6438 23.2519 17.246V3.74595C23.2524 3.49968 23.1923 3.25707 23.0769 3.03953C22.9614 2.82199 22.7942 2.63621 22.59 2.49861Z"
                            fill="#646567"
                        />
                    </svg>

                    <div *ngIf="isOpen" class="navText bold">{{ 'cabinet.navigation.news' | translate }}</div>
                </a>
            </li>

            <li class="menu-list__item firstIndent" *ngIf="isEmployer && hasCMS">
                <a
                    routerLink="/content-management"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="var(&#45;&#45;primary)"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            class="svgActive"
                            d="M9.48 3.46889C7.7864 2.66029 5.41922 2.2642 2.25 2.25014C1.95111 2.24607 1.65809 2.33323 1.41 2.49998C1.20637 2.63762 1.03968 2.82319 0.924597 3.04036C0.809512 3.25754 0.749555 3.49966 0.750002 3.74545V17.1564C0.750002 18.063 1.395 18.7469 2.25 18.7469C5.58141 18.7469 8.92312 19.0581 10.9247 20.95C10.9521 20.976 10.9865 20.9934 11.0237 21C11.0608 21.0066 11.0991 21.0021 11.1338 20.9871C11.1684 20.9721 11.1979 20.9473 11.2186 20.9157C11.2393 20.8841 11.2502 20.8471 11.25 20.8094V5.00732C11.2501 4.90071 11.2273 4.79532 11.1831 4.69828C11.139 4.60124 11.0745 4.5148 10.9941 4.44482C10.5356 4.05287 10.0263 3.7246 9.48 3.46889Z"
                            fill="#646567"
                        />
                        <path
                            class="svgActive"
                            d="M22.59 2.49861C22.3418 2.33227 22.0487 2.24561 21.75 2.25017C18.5808 2.26423 16.2136 2.65845 14.52 3.46892C13.9737 3.72417 13.4643 4.0518 13.0055 4.44298C12.9252 4.51309 12.8609 4.59955 12.8168 4.69658C12.7728 4.7936 12.75 4.89893 12.75 5.00548V20.8085C12.75 20.8447 12.7607 20.8802 12.7807 20.9104C12.8008 20.9407 12.8293 20.9643 12.8628 20.9784C12.8962 20.9924 12.9331 20.9963 12.9687 20.9895C13.0044 20.9827 13.0372 20.9656 13.0631 20.9402C14.2664 19.7449 16.3781 18.7455 21.7519 18.746C22.1497 18.746 22.5312 18.5879 22.8125 18.3066C23.0938 18.0253 23.2519 17.6438 23.2519 17.246V3.74595C23.2524 3.49968 23.1923 3.25707 23.0769 3.03953C22.9614 2.82199 22.7942 2.63621 22.59 2.49861Z"
                            fill="#646567"
                        />
                    </svg>

                    <div *ngIf="isOpen" class="navText bold">
                        {{ 'cabinet.navigation.content-management' | translate }}
                    </div>
                </a>
            </li>

            <div class="menuColapseButton">
                <li class="menu-list__item">
                    <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                        <svg
                            class="arrowIcon"
                            [ngClass]="isOpen ? 'arrowIcon' : 'arrowIconClose'"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="var(--primary)"
                        >
                            <path
                                class="svgActive"
                                d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                                fill="var(--primary)"
                            />
                        </svg>
                        <div *ngIf="isOpen" class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
                    </a>
                </li>
            </div>
        </ul>
        <div *ngIf="isOpen" class="version-container">
            <div class="version">Build: {{ version.semverString }} ({{ version.date }})</div>
        </div>
    </div>
</div>
