import { Component } from '@angular/core';

const isObject = function (item) {
    return item && typeof item === 'object' && !Array.isArray(item);
};

const mergeDeep = function (target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, {
                        [key]: source[key],
                    });
                else output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, {
                    [key]: source[key],
                });
            }
        });
    }
    return output;
};

const sanitizeHtml = function (markup) {
    markup = markup.replace(/&/g, '&amp;');
    markup = markup.replace(/</g, '&lt;');
    markup = markup.replace(/>/g, '&gt;');
    return markup;
};

const embedMarkups = {
    youtube: `<div class="embed"><iframe class="embed-youtube" frameborder="0" src="<%data.embed%>" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen <%data.length%>></iframe></div>`,

    twitter: `<blockquote class="twitter-tweet" class="embed-twitter" <%data.length%>><a href="<%data.source%>"></a></blockquote> <script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>`,

    instagram: `<blockquote class="instagram-media" <%data.length%>><a href="<%data.embed%>/captioned"></a></blockquote><script async defer src="//www.instagram.com/embed.js"></script>`,

    codepen: `<div class="embed"><iframe <%data.length%> scrolling="no" src="<%data.embed%>" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true"></iframe></div>`,

    defaultMarkup: `<div class="embed"><iframe src="<%data.embed%>" <%data.length%> class="embed-unknown" allowfullscreen="true" frameborder="0" ></iframe></div>`,
};

export class EdjsParser {
    config: any;
    parsers: any;

    constructor(config = {}, customs = {}, embeds = {}) {
        var defaultParsers = {
            paragraph: function (parent, data, config) {
                if (data.text === '') {
                    return `<br>`;
                }
                return `<p class="${config.paragraph.pClass}"> ${data.text} </p>`;
            },

            header: function (parent, data) {
                return `<h${data.level}>${data.text}</h${data.level}>`;
            },

            list: function (parent, data) {
                const type = data.style === 'ordered' ? 'ol' : 'ul';
                const items = data.items.reduce((acc, item) => acc + `<li>${item}</li>`, '');
                return `<${type}>${items}</${type}>`;
            },

            quote: function (parent, data, config) {
                let alignment = '';
                if (config?.quote.applyAlignment) {
                    alignment = `style="text-align: ${data.alignment};"`;
                }
                return `<blockquote ${alignment}><p>${data.text}</p><cite>${data.caption}</cite></blockquote>`;
            },

            table: function (parent, data) {
                const rows = data.content.map((row) => {
                    return `<tr>${row.reduce((acc, cell) => acc + `<td>${cell}</td>`, '')}</tr>`;
                });
                return `<table><tbody>${rows.join('')}</tbody></table>`;
            },
            image: function (parent, data, config) {
                const imageConditions = `${data.stretched ? 'img-fullwidth' : ''} ${
                    data.withBorder ? 'img-border' : ''
                } ${data.withBackground ? 'img-bg' : ''}`;
                const imgClass = config.image.imgClass || '';
                let imageSrc;

                if (data.url) {
                    // simple-image was used and the image probably is not uploaded to this server
                    // therefore, we use the absolute path provided in data.url
                    // so, config.image.path property is useless in this case!
                    imageSrc = data.url;
                } else if (config.image.path === 'absolute') {
                    imageSrc = data.file.url;
                } else {
                    imageSrc = config.image.path.replace(/<(.+)>/, (match, p1) => data.file[p1]);
                }

                return `<div class="container-img"><img class="${imgClass} ${imageConditions}" src="${imageSrc}" alt="${data.caption}"></div>`;
            },
            attaches: function (parent, data, config) {
                return `<div class="attaches">
                <div class="cdx-block">
                    <div class="cdx-attaches cdx-attaches--with-file">
                        <div class="cdx-attaches__file-icon">
                            <div class="cdx-attaches__file-icon-background">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M13.3236 8.43554L9.49533 12.1908C9.13119 12.5505 8.93118 13.043 8.9393 13.5598C8.94741 14.0767 9.163 14.5757 9.53862 14.947C9.91424 15.3182 10.4191 15.5314 10.9422 15.5397C11.4653 15.5479 11.9637 15.3504 12.3279 14.9908L16.1562 11.2355C16.8845 10.5161 17.2845 9.53123 17.2682 8.4975C17.252 7.46376 16.8208 6.46583 16.0696 5.72324C15.3184 4.98066 14.3086 4.55425 13.2624 4.53782C12.2162 4.52138 11.2193 4.91627 10.4911 5.63562L6.66277 9.39093C5.57035 10.4699 4.97032 11.9473 4.99467 13.4979C5.01903 15.0485 5.66578 16.5454 6.79264 17.6592C7.9195 18.7731 9.43417 19.4127 11.0034 19.4374C12.5727 19.462 14.068 18.8697 15.1604 17.7907L18.9887 14.0354"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div class="cdx-attaches__file-info">
                            <a
                                class="cdx-attaches__title"
                                contenteditable="false"
                                data-placeholder="File title"
                                href="${data.file.url}"
                                download="${data.title}"
                                style="color: #212529;"
                            >
                                ${data.title}
                            </a>
                        </div>
                        <a
                            class="cdx-attaches__download-button"
                            href="${data.file.url}"
                            download="${data.title}"
                            target="_blank"
                            rel="nofollow noindex noreferrer"
                            ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                    d="M7 10L11.8586 14.8586C11.9367 14.9367 12.0633 14.9367 12.1414 14.8586L17 10"
                                ></path></svg>
                        </a>
                    </div>
                </div>
            </div>
            
            `;
            },
            code: function (parent, data, config) {
                const markup = sanitizeHtml(data.code);
                return `<pre><code class="${config.code.codeBlockClass}">${markup}</code></pre>`;
            },
            raw: function (parent, data) {
                return data.html;
            },
            delimiter: function (parent, data) {
                return '<br />';
            },
            twoColumns: function (parent, data, config) {
                let html = '<div class="columns"><div class="column1">';
                html += parent.parse(data.itemContent['1']);

                html += '</div><div class="column2">';

                html += parent.parse(data.itemContent['2']);
                html += '</div></div>';

                return html;
            },

            embed: function (parent, data, config) {
                if (config.embed.useProvidedLength) {
                    data.length = `width="${data.width}" height="${data.height}"`;
                } else {
                    data.length = '';
                }
                const regex = new RegExp(/<%data\.(.+?)%>/, 'gm');
                if (config.embedMarkups[data.service]) {
                    return config.embedMarkups[data.service].replace(regex, (match, p1) => data[p1]);
                } else {
                    return config.embedMarkups['defaultMarkup'].replace(regex, (match, p1) => data[p1]);
                }
            },
        };

        var defaultConfig = {
            image: {
                use: 'figure', // figure or img (figcaption will be used for caption of figure)
                imgClass: 'img-parser',
                figureClass: 'fig-img',
                figCapClass: 'fig-cap',
                path: 'absolute',
            },
            paragraph: {
                pClass: 'paragraph',
            },
            code: {
                codeBlockClass: 'code-block',
            },
            embed: {
                useProvidedLength: false,
                // set to true if you want the returned width and height of editorjs to be applied
                // NOTE: sometimes source site overrides the lengths so it does not work 100%
            },
            quote: {
                applyAlignment: false,
                // if set to true blockquote element will have text-align css property set
            },
        };
        this.config = mergeDeep(defaultConfig, config);
        this.config.embedMarkups = Object.assign(embedMarkups, embeds);
        this.parsers = Object.assign(defaultParsers, customs);
    }

    parse(EditorJsObject) {
        const html = EditorJsObject.blocks.map((block) => {
            const markup = this.parseBlock(block);
            if (markup instanceof Error) {
                return ''; // parser for this kind of block doesn't exist
            }
            return markup;
        });
        return html.join('');
    }

    parseBlock(block) {
        if (!this.parsers[block.type]) {
            return new Error(`${block.type} is not supported! Define your own custom function.`);
        }
        try {
            return this.parsers[block.type](this, block.data, this.config);
        } catch (err) {
            return err;
        }
    }
}
