import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalEventsService {
    constructor() {}

    public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();

    public branding: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public branding$: Observable<any> = this.branding.asObservable();

    public hasCabinet: ReplaySubject<any> = new ReplaySubject<any>(null);
    public hasCabinet$: Observable<any> = this.hasCabinet.asObservable();

    public logo: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public logo$: Observable<string> = this.logo.asObservable();

    public bookingTypes: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public bookingTypes$: Observable<any> = this.bookingTypes.asObservable();

    public akProfile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public akProfile$: Observable<any> = this.akProfile.asObservable();

    public homePageCards: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public homePageCards$: Observable<any> = this.homePageCards.asObservable();

    public isLoading: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public isLoading$: Observable<any> = this.isLoading.asObservable();

    public adhocReportTemplate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public adhocReportTemplate$: Observable<any> = this.adhocReportTemplate.asObservable();

    setIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated.next(isAuthenticated);
    }

    getIsAuthenticated(): boolean {
        return this.isAuthenticated.getValue();
    }

    getBranding(): any {
        return this.branding.getValue();
    }

    setBranding(branding: any) {
        this.branding.next(branding);
    }

    setHasCabinet(hasCabinet: any) {
        this.hasCabinet.next(hasCabinet);
    }

    getLogo(): any {
        return this.logo.getValue();
    }

    setLogo(logo: any) {
        this.logo.next(logo);
    }

    getBookingTypes() {
        return this.bookingTypes.getValue();
    }

    setBookingTypes(bookingTypes: any) {
        this.bookingTypes.next(bookingTypes);
    }

    getAkProfile() {
        return this.akProfile.getValue();
    }

    setAkProfile(profile: any) {
        this.akProfile.next(profile);
    }

    getHomePageCards() {
        return this.homePageCards.getValue();
    }

    setHomePageCards(cards: any) {
        this.homePageCards.next(cards);
    }

    getIsLoading() {
        return this.isLoading.getValue();
    }

    setIsLoading(value: boolean) {
        this.isLoading.next(value);
    }
}
