import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthenticationService } from 'clan-auth';
import { UserService } from '../../../../core/services/user.service';
import { FormControl } from '@angular/forms';
import Role from '../../../../core/models/Role';
import { VERSION } from '../../../../../assets/version';
import { Subscription, zip } from 'rxjs';
import { GlobalEventsService } from 'src/app/services/global/global-events-service';
import { CustomConfigurationService } from 'src/app/core/services/custom-configuration.service';

@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss'],
    animations: [
        trigger('openCloseNav', [
            state(
                'openNav',
                style({
                    maxHeight: '325px',
                    overflow: 'hidden',
                })
            ),
            state(
                'closedNav',
                style({
                    height: '56px',
                    overflow: 'hidden',
                })
            ),
            transition('openNav => closedNav', [animate('0.5s')]),
            transition('closedNav => openNav', [animate('0.5s')]),
        ]),
    ],
})
export class NavigationMenuComponent implements OnInit {
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = window.innerWidth;
    }
    @Output() navigationToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    screenWidth;
    isSmallNavigationMenu = false;
    isOpen = true;
    isSimulationsOpen = false;
    isAKAccountOpen = false;
    isPensionFundOpen = false;
    trinities: Role[] = null;
    companyNameMap: Map<string, string>;
    companyForm = new FormControl();
    version;
    isNotPensioner: boolean = false;
    isEmployer: boolean = false;
    hasWEF: boolean = true;
    hasCMS: boolean = false;
    hasNews: boolean = false;
    public logoUrl = '';
    public showAK = false;
    public showCabinet = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private user: UserService,
        private auth: AuthenticationService,
        private globalEventsService: GlobalEventsService,
        private customConfigurationService: CustomConfigurationService
    ) {
        this.version = VERSION;
        this.subscription.add(
            this.globalEventsService.logo.subscribe((logo) => {
                this.logoUrl = logo;
            })
        );
        this.subscription.add(
            this.user.hasAK$.subscribe((hasAk) => {
                this.showAK = hasAk;
            })
        );

        this.subscription.add(
            this.globalEventsService.hasCabinet.subscribe((hasCabinet) => {
                this.showCabinet = hasCabinet;
            })
        );
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        if (window.innerWidth < 700) {
            this.isOpen = false;
        }

        this.user.$allCompanies.subscribe({
            next: (trinities) => {
                this.trinities = trinities;
                this.companyForm.setValue(this.trinities);
            },
        });
        this.user.$companyNameMap.subscribe({
            next: (map) => (this.companyNameMap = map),
        });
        if (this.auth.isValid()) {
            this.user.getData();
            this.checkWef();
            this.user.isNotPensioner$.subscribe((isNotPensioner: boolean) => {
                this.isNotPensioner = isNotPensioner;
            });
            this.user.isEmployer$.subscribe((isEmployer: boolean) => {
                this.isEmployer = isEmployer;
            });
        }
    }

    menuOpen() {
        if (this.isOpen == true) {
            if (this.isSimulationsOpen) this.simulationsMenuClick();
            if (this.isAKAccountOpen) this.akAccountMenuClick();
        }
        this.isOpen = !this.isOpen;
        this.navigationToggle.emit(this.isOpen);
    }
    simulationsMenuClick() {
        this.isSimulationsOpen = !this.isSimulationsOpen;
    }
    akAccountMenuClick() {
        this.isAKAccountOpen = !this.isAKAccountOpen;
    }
    pensionFundClick() {
        this.isPensionFundOpen = !this.isPensionFundOpen;
    }
    changeCompany(event) {
        if (event.isUserInput) {
            if (event.source.selected) {
                this.user.addSelectedCompany(event.source.value);
            } else {
                this.user.removeSelectedCompany(event.source.value);
            }
        }
    }

    checkWef() {
        zip(
            this.customConfigurationService.getCustomConfiguration$('companyConfig'),
            this.user.$currentPerson
        ).subscribe(([config, person]) => {
            const company = person.roles.find((role) => role.roleType === 'EMPLOYEE').companyId;
            const customFeatures = config?.config[company]?.employee?.features;
            this.hasWEF = customFeatures?.wef === false ? false : true;
            sessionStorage.setItem('hasWEF', this.hasWEF.toString());
            const cms = customFeatures?.contentManagment;
            this.hasCMS = cms;
            this.customConfigurationService.setCMS(this.hasCMS);
            let news = customFeatures?.news;
            this.hasNews = news;
            this.customConfigurationService.setNews(news);
        });
    }
}
