<div class="page-container">
    <div class="page-title">{{ 'cm.title' | translate | uppercase }}</div>
    <div class="table-filters">
        <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
            <mat-label>{{ 'cm.filters.title' | translate }}</mat-label>
            <input matInput placeholder="Filter" [(ngModel)]="filters.title" (input)="applyFilter()" />
            <button mat-icon-button matSuffix *ngIf="filters.title" (click)="clearDateRange('title')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
            <mat-label>{{ 'cm.filters.render-engine' | translate }}</mat-label>
            <mat-select [(ngModel)]="filters.renderEngine" (selectionChange)="applyFilter()" [disabled]="true">
                <mat-option *ngFor="let engine of renderEngines" [value]="engine.value">{{ engine.key }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
            <mat-label>{{ 'cm.filters.create-date' | translate }}</mat-label>
            <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                <input
                    matStartDate
                    formControlName="start"
                    [placeholder]="startDateTranslation"
                    (dateInput)="applyFilter()"
                />
                <input
                    matEndDate
                    formControlName="end"
                    [placeholder]="endDateTranslation"
                    (dateInput)="applyFilter()"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <button
                mat-icon-button
                matSuffix
                *ngIf="dateRange.value.start || dateRange.value.end"
                (click)="clearDateRange('dateRange')"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <button
            *ngIf="hasUserEmployerRole"
            class="filter-element btn-rounded wider create-button button"
            (click)="openCreateNewContent()"
        >
            {{ 'cm.create-new' | translate }}
        </button>
    </div>
    <div *ngIf="isLoading" class="loading-overlay">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading" class="table-container">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.id' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">{{ item.id }}</td>
            </ng-container>

            <ng-container matColumnDef="contentType">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.content-type' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">
                    {{ 'cm.content-types.' + item.contentType | translate | uppercase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="renderEngine">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.render-engine' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">{{ item.renderEngine }}</td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.content-title' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">{{ item.title[locale] ?? item.title.de }}</td>
            </ng-container>

            <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.create-date' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">{{ item.createdDate | date : 'dd.MM.yyyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="editDate">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.edit-date' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">{{ item.editedDate | date : 'dd.MM.yyyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.status' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">{{ item.status }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.actions' | translate | uppercase }}</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button (click)="editContent(item)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteContent(item)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <mat-paginator
        *ngIf="!isLoading"
        class="paginator"
        [length]="totalElements"
        [pageSize]="filters.size"
        [pageIndex]="filters.page"
        [pageSizeOptions]="[10, 20, 30, 50, 100]"
        (page)="pageEvent($event)"
    ></mat-paginator>
</div>
