import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { GlobalEventsService } from 'src/app/services/global/global-events-service';

@Injectable({
    providedIn: 'root',
})
export class CabinetGuard implements CanActivate {
    constructor(private globalEventsService: GlobalEventsService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.globalEventsService.hasCabinet$.pipe(
            map((hasCabinet) => {
                if (hasCabinet) {
                    return true;
                }

                this.router.navigate(['/home']);
                return false;
            }),
            catchError((error) => {
                this.router.navigate(['/home']);
                return of(false);
            })
        );
    }
}
