import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { SnackbarService } from '../ak/services/snackbar.service';
import { of } from 'rxjs';

export function mapContentToFormData(content, renderEngines, documentLocations) {
    const formData = {
        id: content?.id,
        de_title: content.title?.de ?? content.de_title,
        en_title: content.title?.en ?? content.en_title,
        fr_title: content.title?.fr ?? content.fr_title,
        it_title: content.title?.it ?? content.it_title,
        renderEngine: renderEngines.find((renderEngine) => renderEngine.key === content.renderEngine),
        contentType: content.contentType,
        location: documentLocations.find((location) => location.id === content.locationId),
        status: content.status,
        imageThumbnail: returnBytes(content),
        targetGroup: content.targetGroup,
        de_content: content.content?.de ?? content.de_content,
        en_content: content.content?.en ?? content.en_content,
        fr_content: content.content?.fr ?? content.fr_content,
        it_content: content.content?.it ?? content.it_content,
    };

    return formData;
}

export function mapFormDataToContent(formData, user, company, pensionFund, foundation, thumbnailChanged) {
    const content = {
        id: formData?.id ?? null,
        title: {
            de: formData?.de_title,
            en: formData?.en_title,
            fr: formData?.fr_title,
            it: formData?.it_title,
        },
        content: {
            de: formData?.de_content,
            en: formData?.en_content,
            fr: formData?.fr_content,
            it: formData?.it_content,
        },
        status: formData?.status,
        imageThumbnail: thumbnailChanged
            ? getThumbnailRequest(user, company, pensionFund, foundation, formData?.imageThumbnail)
            : null,
        contentType: formData?.contentType,
        renderEngine: formData?.renderEngine.value,
        locationId: formData?.location.id,
        targetGroup: formData?.targetGroup,
        company: company,
        user: user,
        pensionFund: pensionFund,
        foundation: foundation,
        thumbnailChanged: thumbnailChanged,
    };

    return content;
}

export function getThumbnailRequest(user, company, pensionFund, foundation, thumbnail) {
    if (!thumbnail) {
        return null;
    }

    const thumbnailRequest = {
        service: 'ALFRESCO',
        owner: {
            personId: user._id,
            companyId: company._id,
            pensionFundId: pensionFund.pensionFundId,
            foundationId: foundation.foundationId,
        },
        documentName: '' + uuidv4() + '_' + thumbnail.name,
        template: {
            templateType: 'personTemplate',
            documentTitle: thumbnail.name,
            documentDate: new Date(Date.now()).toISOString().slice(0, -1),
            documentType: 'VA',
            documentClass: 'ATTACHMENT',
            personId: user._id,
            companyId: company._id,
            year: new Date(Date.now()).getFullYear(),
        },
        content: {
            mimeType: thumbnail.type,
            payload: thumbnail.bytes,
        },
    };

    return thumbnailRequest;
}

export function prepareBytes(article) {
    if (!article.imageThumbnail || !article.imageThumbnail.bytes) {
        return;
    }

    article.imageThumbnail.url = `data:${article.imageThumbnail.type};base64,${article.imageThumbnail.bytes}`;
}

export function returnBytes(article) {
    if (!article.imageThumbnail || !article.imageThumbnail.bytes) {
        return null;
    }

    article.imageThumbnail.url = `data:${article.imageThumbnail.type};base64,${article.imageThumbnail.bytes}`;
    return article.imageThumbnail;
}

export function getContentThumbnail(file: File, arrayBuffer: string) {
    const imageThumbnail = {
        url: `data:${file.type};base64,${arrayBuffer}`,
        bytes: arrayBuffer,
        name: file.name,
        type: file.type,
    };
    return imageThumbnail;
}

export function createThumbnail(thumbnailUrl: string) {
    const thumbnail = document.createElement('img');
    thumbnail.src = thumbnailUrl;
    thumbnail.id = 'img';
    thumbnail.alt = 'Thumbnail';
    thumbnail.style.maxHeight = '100px';

    const container = document.getElementById('thumbnail-container');
    container.innerHTML = '';
    container.appendChild(thumbnail);
}

export function handleCompressionErrors(error, translateService: TranslateService, snackBarService: SnackbarService) {
    let errorMessage;
    switch (error.error) {
        case 'INVALID_EXTENSION':
            errorMessage = translateService.instant('cabinet.news.invalidFileExtension');
            break;
        case 'PNG_WITH_ALPHA':
            errorMessage = translateService.instant('cabinet.news.pngWithAlpha');
            break;
        default:
            errorMessage = translateService.instant('cabinet.news.imageTooLarge');
            break;
    }
    snackBarService.showErrorAlert(errorMessage);
    return of(null);
}
